import { announcementsConstants } from '../constants/index';

const initialState = [];

export function announcementsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case announcementsConstants.ADD_ANNOUNCEMENTS:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        case announcementsConstants.GET_ANNOUNCEMENTS:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        case announcementsConstants.DEL_ANNOUNCEMENTS:
            console.log(payload);
            return payload
        case announcementsConstants.MODIFY_ANNOUNCEMENTS:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}