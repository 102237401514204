import { departmentsConstrants } from '../constants';
import { departmentsService } from '../services';
//import { history } from '../helpers';
import React, { Component } from 'react';

export const addDepartments = (data) => {
    return dispatch => {
        departmentsService.addDepartments(data)
            .then(
                response => {
                    dispatch(addSuccess(response));
                }
            )
    }

    function addSuccess(report) {
        return {
            type: departmentsConstrants.ADD_DEPARTMENTS,
            payload: report
        }
    }
}

export const addDepartmentMembers = (deptId, data) => {
    return dispatch => {
        departmentsService.addDepartmentMembers(deptId, data)
            .then(
                response => {
                    dispatch(addSuccess(response));
                }
            )
    }

    function addSuccess(report) {
        return {
            type: departmentsConstrants.ADD_DEPARTMENTS_MEMBER,
            payload: report
        }
    }
}

export const updateDepartments = (id, data) => {
    return dispatch => {
        departmentsService.updateDepartments(id, data)
            .then(
                response => {
                    dispatch(updateSuccess(response));
                    window.location = '/#/departments';
                }
            )
    }

    function updateSuccess(report) {
        return {
            type: departmentsConstrants.UPDATE_DEPARTMENTS,
            payload: report
        }
    }
}

export const deleteDepartments = (id) => {
    return dispatch => {
        departmentsService.deleteDepartments(id)
            .then(
                response => {
                    dispatch(deleteSuccess(response));
                    window.location = '/#/departments';
                }
            )
    }

    function deleteSuccess(report) {
        return {
            type: departmentsConstrants.DELETE_DEPARTMENTS,
            payload: report
        }
    }
}

export const getDepartments = () => {
    return dispatch => {
        departmentsService.getDepartments()
            .then(
                response => {
                    dispatch(retrieveSuccess(response));
                }
            )
    }

    function retrieveSuccess(report) {
        return {
            type: departmentsConstrants.RETRIEVE_DEPARTMENTS,
            payload: report
        }
    }
}
