import { userConstants } from '../constants';
import { authenticationService } from '../services';
//import { history } from '../helpers';
import React, { Component } from 'react';


export const userActions = {
	login,
	logout,
	first_login,
	set_password
};

function login(email, password) {
	//console.log(email + "=====" + password);

	return dispatch => {
		dispatch(request({ email }));

		authenticationService.login(email, password)
			.then(
				user => {
					dispatch(success(user));
					window.location.href = '/#/dashboard';
				},
				error => {
					dispatch(failure(error.toString()));
				}
			);
	}

	function request(user) {
		return { type: userConstants.LOGIN_REQUEST, user }
	}
	function success(user) {
		return { type: userConstants.LOGIN_SUCCESS, user }
	}
	function failure(error) {
		return { type: userConstants.LOGIN_FAILURE, error }
	}
}

function first_login(email, pancard) {
	return dispatch => {
		authenticationService.first_login(email, pancard)
			.then(
				user => {
					dispatch(success(user));
					window.location.href = '/#/set-password';
				}
			);
	}

	function success(user) {
		return {
			type: userConstants.FIRST_LOGIN,
			payload: user
		}
	}
}

function set_password(data) {
	return dispatch => {
		authenticationService.set_password(data)
			.then(
				user => {
					dispatch(success(user));
				}
			);
	}

	function success(user) {
		return {
			type: userConstants.CREATE_PASSWORD,
			payload: user
		}
	}
}

function logout() {
	authenticationService.logout();
	return { type: userConstants.LOGIN_REQUEST };
}