import { employeeConstrants } from '../constants';
import { employeeService, authenticationService } from '../services';
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

const currentUser = authenticationService.currentUserValue;

export const retrieveAllEmployee = () => {
    return dispatch => {
        employeeService.getAllEmployee(currentUser.token)
            .then(
                response => {
                    dispatch(retrieveSuccess(response));
                }
            )
    }

    function retrieveSuccess(response) {
        return {
            type: employeeConstrants.RETRIEVE_EMPLOYEE,
            payload: response
        }
    }
}

export const updateEmployee = (id, token, data) => {
    return dispatch => {
        employeeService.updateEmployee(id, token, data)
            .then(
                response => {
                    dispatch(updateSuccess(response));
                }
            )
    }

    function updateSuccess(data) {
        return {
            type: employeeConstrants.UPDATE_EMPLOYEE,
            payload: data
        }
    }
}

export const retrieveEmployeeRatio = () => {
    return dispatch => {
        employeeService.employeeRatio(currentUser.token)
            .then(
                response => {
                    dispatch(retrieveSuccess({ 'employee_count': response }));
                }
            )
    }

    function retrieveSuccess(response) {
        return {
            type: employeeConstrants.RETRIEVE_EMPLOYEE_RATIO,
            payload: response
        }
    }
}

export const retrieveEmployeeNames = () => {
    return dispatch => {
        employeeService.getAllEmployeeNames(currentUser.token)
            .then(
                response => {
                    dispatch(retrieveSuccess({ 'employees': response }));
                }
            )
    }

    function retrieveSuccess(response) {
        return {
            type: employeeConstrants.RETRIEVE_EMPLOYEE_NAMES,
            payload: response
        }
    }
}

export const retrieveEmployeeNewJoinings = () => {
    return dispatch => {
        employeeService.employeeNewJoinings(currentUser.token)
            .then(
                response => {
                    dispatch(retrieveSuccess({ 'new_joinings': response }));
                }
            )
    }

    function retrieveSuccess(response) {
        return {
            type: employeeConstrants.RETRIEVE_EMPLOYEE_NEW_JOININGS,
            payload: response
        }
    }
}

export const retrieveEmployeeWorkAnniversary = () => {
    return dispatch => {
        employeeService.employeeWorkAnniversary(currentUser.token)
            .then(
                response => {
                    dispatch(retrieveSuccess({ 'new_joinings': response }));
                }
            )
    }

    function retrieveSuccess(response) {
        return {
            type: employeeConstrants.RETRIEVE_EMPLOYEE_NEW_JOININGS,
            payload: response
        }
    }
}

export const retrieveEmployeeBirthday = () => {
    return dispatch => {
        employeeService.employeeBirthday(currentUser.token)
            .then(
                response => {
                    dispatch(retrieveSuccess({ 'new_joinings': response }));
                }
            )
    }

    function retrieveSuccess(response) {
        return {
            type: employeeConstrants.RETRIEVE_EMPLOYEE_NEW_JOININGS,
            payload: response
        }
    }
}