import { employeeConstrants } from '../constants/index';

const initialState = [];

export function employeeReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case employeeConstrants.RETRIEVE_EMPLOYEE:
            console.log(payload);
            return payload
        case employeeConstrants.UPDATE_EMPLOYEE:
            console.log(payload);
            return state
        case employeeConstrants.RETRIEVE_EMPLOYEE_NAMES:
            console.log(payload);
            return payload
        case employeeConstrants.RETRIEVE_EMPLOYEE_RATIO:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        case employeeConstrants.RETRIEVE_EMPLOYEE_NEW_JOININGS:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        case employeeConstrants.RETRIEVE_EMPLOYEE_WORK_ANNIVERSARY:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        case employeeConstrants.RETRIEVE_EMPLOYEE_BIRTHDAY:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}