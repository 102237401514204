import { salariesConstants } from '../constants';
import { salaryServices } from '../services';

export const get_emp_salaries = (month, year) => {
    return dispatch => {
        salaryServices.get_emp_salaries(month, year)
            .then(
                salary => {
                    dispatch(success(salary));
                }
            );
    }

    function success(salary) {
        return {
            type: salariesConstants.GET_EMP_SALARIES,
            payload: salary
        }
    }
}

export const get_emp_salary = (employee_id, month, year) => {
    return dispatch => {
        salaryServices.get_emp_salary(employee_id, month, year)
            .then(
                salary => {
                    dispatch(success(salary));
                }
            );
    }

    function success(salary) {
        return {
            type: salariesConstants.GET_EMP_SALARY,
            payload: salary
        }
    }
}

export const add_emp_salary = (data) => {
    return dispatch => {
        salaryServices.add_emp_salary(data)
            .then(
                salary => {
                    dispatch(success(salary))
                    window.location.href = '/#/salaries'
                }
            );
    }

    function success(salary) {
        return {
            type: salariesConstants.ADD_EMP_SALARY,
            payload: salary
        }
    }
}