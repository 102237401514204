import { settingsConstrants } from '../constants';
import { settingsService } from '../services';
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

export const changePassword = (data) => {
    return dispatch => {
        settingsService.changePassword(data)
            .then(
                response => {
                    dispatch(applySuccess({ 'change_password': response }));
                }
            )
    }

    function applySuccess(report) {
        return {
            type: settingsConstrants.SETTINGS_CHANGE_PASSWORD,
            payload: report
        }
    }
}