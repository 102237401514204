import { announcementsConstants } from '../constants';
import { announcementService } from '../services';
import React, { Component } from 'react';

export const getAnnouncements = () => {
    return dispatch => {
        announcementService.getAnnouncements()
            .then(
                response => {
                    dispatch(addSuccess({ 'announcements': response }));
                }
            )
    }

    function addSuccess(report) {
        return {
            type: announcementsConstants.GET_ANNOUNCEMENTS,
            payload: report
        }
    }
}

export const addAnnouncements = (data) => {
    return dispatch => {
        announcementService.addAnnouncements(data)
            .then(
                response => {
                    console.log(response)
                    dispatch(addSuccess(response));
                }
            )
    }

    function addSuccess(report) {
        return {
            type: announcementsConstants.ADD_ANNOUNCEMENTS,
            payload: report
        }
    }
}

export const delAnnouncements = (id) => {
    return dispatch => {
        announcementService.delAnnouncements(id)
            .then(
                response => {
                    dispatch(addSuccess(response));
                }
            )
    }

    function addSuccess(report) {
        return {
            type: announcementsConstants.DEL_ANNOUNCEMENTS,
            payload: report
        }
    }
}

export const modifyAnnouncements = (data) => {
    return dispatch => {
        announcementService.modifyAnnouncements(data)
            .then(
                response => {
                    dispatch(addSuccess(response));
                }
            )
    }

    function addSuccess(report) {
        return {
            type: announcementsConstants.MODIFY_ANNOUNCEMENTS,
            payload: report
        }
    }
}