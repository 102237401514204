import { leavemgmtConstrants } from '../constants';
import { leavemgmtService } from '../services';
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

export const applyLeave = (data) => {
    return dispatch => {
        leavemgmtService.applyLeave(data)
            .then(
                response => {
                    dispatch(applySuccess({ 'apply_leave': response.data }));
                }
            )
    }

    function applySuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_APPLY_LEAVE,
            payload: report
        }
    }
}

export const pendingLeave = () => {
    return dispatch => {
        leavemgmtService.pendingLeave()
            .then(
                response => {
                    dispatch(fetchSuccess({ 'pending_leave': response.data }));
                    //window.location = '/#/leavemanagement';
                }
            )
    }

    function fetchSuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_PENDING_LEAVE,
            payload: report
        }
    }
}

export const approvedLeave = () => {
    return dispatch => {
        leavemgmtService.approvedLeave()
            .then(
                response => {
                    dispatch(fetchSuccess({ 'approved_leave': response.data }));
                    //window.location = '/#/leavemanagement';
                }
            )
    }

    function fetchSuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_APPROVED_LEAVE,
            payload: report
        }
    }
}

export const approvedLeaveByEmp = (emp_id) => {
    return dispatch => {
        leavemgmtService.approvedLeaveByEmp(emp_id)
            .then(
                response => {
                    dispatch(fetchSuccess({ 'approved_leave_by_emp': response.data }));
                }
            )
    }

    function fetchSuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_APPROVED_LEAVE_BY_EMP,
            payload: report
        }
    }
}

export const acceptRejectLeave = (data) => {
    return dispatch => {
        leavemgmtService.acceptRejectLeave(data)
            .then(
                response => {
                    dispatch(fetchSuccess(response.data));
                }
            )
    }

    function fetchSuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_ACCEPT_REJECT_LEAVE,
            payload: report
        }
    }
}

export const leaveByEmp = (data) => {
    return dispatch => {
        leavemgmtService.leaveByEmp(data)
            .then(
                response => {
                    dispatch(fetchSuccess({ 'personal_leave': response }));
                }
            )
    }

    function fetchSuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_LEAVE_BY_EMP,
            payload: report
        }
    }
}

export const remainingEmpLeaves = (data) => {
    return dispatch => {
        leavemgmtService.remainingEmpLeaves(data)
            .then(
                response => {
                    dispatch(fetchSuccess({ 'remaining_emp_leaves': response }));
                }
            )
    }

    function fetchSuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_REMAINING_EMP_LEAVE,
            payload: report
        }
    }
}


export const compOffByEmployee = (data) => {
    return dispatch => {
        leavemgmtService.compOffByEmployee(data)
            .then(
                response => {
                    dispatch(fetchSuccess({ 'compensatory_emp_leaves': response }));
                }
            )
    }

    function fetchSuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_REMAINING_EMP_LEAVE,
            payload: report
        }
    }
}


export const weeklyLeavesByEmployee = (data) => {
    return dispatch => {
        leavemgmtService.weeklyLeavesByEmployee(data)
            .then(
                response => {
                    dispatch(fetchSuccess({ 'weekly_emp_leaves': response }));
                }
            )
    }

    function fetchSuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_WEEKLY_EMP_LEAVE,
            payload: report
        }
    }
}


export const monthlyLeavesByEmployee = (data) => {
    return dispatch => {
        leavemgmtService.monthlyLeavesByEmployee(data)
            .then(
                response => {
                    dispatch(fetchSuccess({ 'monthly_emp_leaves': response }));
                }
            )
    }

    function fetchSuccess(report) {
        return {
            type: leavemgmtConstrants.LEAVE_MGMT_MONTHLY_EMP_LEAVE,
            payload: report
        }
    }
}
