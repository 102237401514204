const initialState = {
  sidebarShow: true,
}

export function changeState(state = initialState, { type, ...rest }) {
  //alert(type);
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}