import { settingsConstrants } from '../constants/index';

const initialState = [];

export function settingsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case settingsConstrants.SETTINGS_CHANGE_PASSWORD:
            console.log("Change Password Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}