export const leavemgmtConstrants = {
    LEAVE_MGMT_APPLY_LEAVE: 'LEAVE_MGMT_APPLY_LEAVE',
    LEAVE_MGMT_PENDING_LEAVE: 'LEAVE_MGMT_PENDING_LEAVE',
    LEAVE_MGMT_APPROVED_LEAVE: 'LEAVE_MGMT_APPROVED_LEAVE',
    LEAVE_MGMT_APPROVED_LEAVE_BY_EMP: 'LEAVE_MGMT_APPROVED_LEAVE_BY_EMP',
    LEAVE_MGMT_ACCEPT_REJECT_LEAVE: 'LEAVE_MGMT_ACCEPT_REJECT_LEAVE',
    LEAVE_MGMT_LEAVE_BY_EMP: 'LEAVE_MGMT_LEAVE_BY_EMP',
    LEAVE_MGMT_REMAINING_EMP_LEAVE: 'LEAVE_MGMT_REMAINING_EMP_LEAVE',
    LEAVE_MGMT_COMPENSATORY_EMP_LEAVE: 'LEAVE_MGMT_COMPENSATORY_EMP_LEAVE',
    LEAVE_MGMT_WEEKLY_EMP_LEAVE: 'LEAVE_MGMT_WEEKLY_EMP_LEAVE',
    LEAVE_MGMT_MONTHLY_EMP_LEAVE: 'LEAVE_MGMT_MONTHLY_EMP_LEAVE',
}
