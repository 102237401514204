import { leavemgmtConstrants } from '../constants/index';

const initialState = [];

export function leavemgmtReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case leavemgmtConstrants.LEAVE_MGMT_APPLY_LEAVE:
            console.log("APPLY LEAVE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case leavemgmtConstrants.LEAVE_MGMT_PENDING_LEAVE:
            console.log("PENDING LEAVE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case leavemgmtConstrants.LEAVE_MGMT_APPROVED_LEAVE:
            console.log("APPROVED LEAVE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case leavemgmtConstrants.LEAVE_MGMT_APPROVED_LEAVE_BY_EMP:
            console.log("APPROVED LEAVE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case leavemgmtConstrants.LEAVE_MGMT_ACCEPT_REJECT_LEAVE:
            console.log("ACCEPT/REJECT LEAVE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case leavemgmtConstrants.LEAVE_MGMT_LEAVE_BY_EMP:
            console.log("EMP LEAVE History Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case leavemgmtConstrants.LEAVE_MGMT_REMAINING_EMP_LEAVE:
            console.log("REMAINING EMP LEAVE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case leavemgmtConstrants.LEAVE_MGMT_COMPENSATORY_EMP_LEAVE:
            console.log("COMPENSATORY EMP LEAVE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case leavemgmtConstrants.LEAVE_MGMT_WEEKLY_EMP_LEAVE:
            console.log("WEEKLY EMP LEAVE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case leavemgmtConstrants.LEAVE_MGMT_MONTHLY_EMP_LEAVE:
            console.log("MONTHLY EMP LEAVE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}