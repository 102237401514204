import { manageUserConstants } from '../constants';
import { manageUserService } from '../services';
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

export const updateUser = (id, token, data) => {
    return dispatch => {
        manageUserService.updateUser(id, token, data)
            .then(
                response => {
                    dispatch(editSuccess(response.data));
                    window.location = '/#/manageusers';
                }
            )
    }

    function editSuccess(report) {
        return {
            type: manageUserConstants.UPDATE_USER,
            payload: report
        }
    }
}

export const addUser = (token, data) => {
    return dispatch => {
        manageUserService.addUser(token, data)
            .then(
                response => {
                    dispatch(addSuccess(response.result));
                    window.location = '/#/manageusers';
                }
            )
    }

    function addSuccess(report) {
        return {
            type: manageUserConstants.ADD_USER,
            payload: report
        }
    }
}

export const deleteUser = (token, uid) => {
    return dispatch => {
        manageUserService.deleteUser(uid, token)
            .then(
                response => {
                    dispatch(deleteSuccess(response.result));
                    window.location = '/#/manageusers';
                }
            )
    }

    function deleteSuccess(report) {
        return {
            type: manageUserConstants.DELETE_USER,
            payload: report
        }
    }
}
