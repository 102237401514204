import axios from 'axios';
import { authenticationService } from '../services';

const currentUser = authenticationService.currentUserValue;

export const settingsService = {
    changePassword
};

async function changePassword(data) {
    const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_CHANGE_PASSWORD}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    console.log(response.data)
    return response.data
}