import { holidayListConstraints } from '../constants/index';

const initialState = [];

export function holidayListReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case holidayListConstraints.GET_HOLIDAY_LIST:
            console.log(payload);
            return payload
        case holidayListConstraints.UPDATE_HOLIDAY:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        case holidayListConstraints.DELETE_HOLIDAY:
            console.log(payload);
            return payload
        case holidayListConstraints.UPLOAD_HOLIDAY_LIST:
            console.log(payload);
            return payload
        default:
            return state;
    }
}