import { JobOpeningsConstants } from '../constants';
import { jobsService } from '../services';
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

export const addJobs = (data) => {
    return dispatch => {
        jobsService.addJobs(data)
            .then(
                response => {
                    dispatch(addSuccess(response));
                    window.location = '#/jobopenings';
                }
            )
    }

    function addSuccess(report) {
        return {
            type: JobOpeningsConstants.ADD_JOBS,
            payload: report
        }
    }
}

export const updateJob = (id, data) => {
    return dispatch => {
        jobsService.updateJob(id, data)
            .then(
                response => {
                    dispatch(updateSuccess(response));
                }
            )
    }

    function updateSuccess(report) {
        return {
            type: JobOpeningsConstants.UPDATE_JOB,
            payload: report
        }
    }
}

export const deleteJob = (id) => {
    return dispatch => {
        jobsService.deleteJob(id)
            .then(
                response => {
                    dispatch(deleteSuccess(response));
                }
            )
    }

    function deleteSuccess(report) {
        return {
            type: JobOpeningsConstants.DELETE_JOB,
            payload: report
        }
    }
}

export const getSingleJob = (id) => {
    return dispatch => {
        jobsService.getSingleJob(id)
            .then(
                response => {
                    dispatch(retrieveSuccess(response));
                }
            )
    }

    function retrieveSuccess(report) {
        return {
            type: JobOpeningsConstants.GET_JOB,
            payload: report
        }
    }
}

export const getJobs = () => {
    return dispatch => {
        jobsService.getJobs()
            .then(
                response => {
                    dispatch(retrieveSuccess(response));
                }
            )
    }

    function retrieveSuccess(report) {
        return {
            type: JobOpeningsConstants.GET_ALL_JOBS,
            payload: report
        }
    }
}

export const latestJobOpenings = () => {
    return dispatch => {
        jobsService.latestJobOpenings()
            .then(
                response => {
                    dispatch(retrieveSuccess(response));
                }
            )
    }

    function retrieveSuccess(report) {
        return {
            type: JobOpeningsConstants.GET_LATEST_JOB_OPENINGS,
            payload: report
        }
    }
}