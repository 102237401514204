import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { API_URL_ROOT } from "../actions/action.constant";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const tempUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('tempUser')));

export const authenticationService = {
    login,
    logout,
    first_login,
    set_password,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    tempUser: tempUserSubject.asObservable(),
    get tempUserValue() {
        return tempUserSubject.value
    }
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
    };

    return fetch(process.env.REACT_APP_API + process.env.REACT_APP_API_LOGIN, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            //console.log(localStorage.getItem('currentUser'));
            currentUserSubject.next(user);
            console.log(user)
            return user;
        });
}

function first_login(email, pan) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, pan }),
    };

    return fetch(process.env.REACT_APP_API + process.env.REACT_APP_API_FIRST_LOGIN, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('tempUser', JSON.stringify(user));
            tempUserSubject.next(user);
            console.log(user)
            return user;
        });
}

function set_password(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // body: data,
    };

    let params = `?email=${data.email}&pan_card=${data.pan_card}&password=${data.password}&confirm_password=${data.confirm_password}`

    return fetch(process.env.REACT_APP_API + process.env.REACT_APP_API_SET_PASSWORD + params, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log(user)
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tempUser');
    currentUserSubject.next(null);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        // console.log(data);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            } else if (response.status === 406) {
                window.location.href = '/#/first-login'
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}