import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { userActions } from '../../actions'
import {
  CCard,
  CCol,
  CForm,
  CRow,
  CCardImage
} from '@coreui/react'
import { connect } from 'react-redux'
import { useFormik } from "formik"

import login_ilustration from '../../assets/vcs_assets/hrms_ilustrations_13.png';

import useWindowSize from 'src/components/windowSize';

import './account.scss';
import '../../scss/hrms-style.scss';

import HrButton from 'src/components/hr-button';
import HrFormInput from 'src/components/hr-form-input';

const Login = (props) => {
  const navigate = useNavigate();

  const size = useWindowSize();

  useEffect(() => {
    if (props.loggedIn) {
      navigate('/#/dashboard');
      return;
    }

    if (props.loggedOut) {
      props.logout();
    }
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values) => {
      if (values.email && values.password) {
        props.login(values.email, values.password);
      }
    },
    validate: values => {
      let errors = {};

      if (!values.email) {
        errors.email = 'Email is required'
      } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
        errors.email = 'Invalid email format!'
      }

      if (!values.password) {
        errors.password = 'Password is required'
      }

      return errors
    }
  });

  return (
    <div className="p-0 border-0 hrms-account-body">
      <CCol xs={12} sm={12} md={9} lg={6} className="m-0 p-2">
        <CCard className="d-flex flex-row hrms-common-card border-0 polaroid" style={{ minHeight: '50vh' }}>
          {
            size.width > 600
              ? <CCol md={6} xs={0} className="m-0 p-0">
                <CCardImage src={login_ilustration} className="hrms-account-card-image" />
              </CCol>
              : null
          }

          <CCol md={6} className="m-0 p-0 d-flex justify-content-center align-items-center">
            <div className="m-0 p-0 d-flex row">
              <CCol md={12} className="m-0 p-3 hrms-account-form-header">
                <h5 className='hrms-font-link'>Sign in to</h5>
                <h4 className='hrms-font-link'>HR Management</h4>
              </CCol>
              <CCol md={12} className="m-0 px-3 mb-3">
                <CForm className="row g-1" onSubmit={formik.handleSubmit}>
                  <CCol xs={12} className="mb-1 px-3">
                    <HrFormInput
                      id='email'
                      className={formik.errors.email ? 'is-invalid' : ''}
                      title='EMail'
                      type='email'
                      placeholder='example@voyantcs.com'
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.errors.email && <div className="invalid-feedback d-block">{formik.errors.email}</div>}
                  </CCol>

                  <CCol xs={12} className="mb-1 px-3">
                    <HrFormInput
                      id='password'
                      className={formik.errors.password ? 'is-invalid' : ''}
                      title='Password'
                      type='password'
                      placeholder='Password'
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    {formik.errors.password && <div className="invalid-feedback d-block">{formik.errors.password}</div>}
                  </CCol>

                  <CCol xs={12} className="p-0 m-0 px-3 d-flex justify-content-end align-items-center mt-2 mb-2" style={{ fontSize: '13px' }}>
                    Forgot password? &nbsp;
                    <div className="m-0 p-0 hrms-hyperlink-btn" onClick={() => navigate('/forgot_password/verify_email')}>Reset</div>
                  </CCol>

                  <CCol xs={12} className="mb-2 px-3">
                    <HrButton title="Login" type='submit' />
                  </CCol>

                  <CCol xs={12} className="m-0 p-0 px-3">
                    <div className="d-flex justify-content-center align-items-center p-0 m-0 mt-2 hrms-font-link" style={{ fontSize: '13px' }}>
                      <div>Not verified?</div>
                      &nbsp;
                      <div className="m-0 p-0 hrms-hyperlink-btn" onClick={() => navigate('/first-login')}>Verify Now</div>
                    </div>
                  </CCol>
                </CForm>
              </CCol>
            </div>
          </CCol>
        </CCard>
      </CCol>
    </div>
  )
}

function mapStateToProps(state) {
  const { loggingIn, loggedIn, loggedOut } = state.authentication;
  if (loggedIn) {
    return { loggedIn };
  } else if (loggingIn) {
    return { loggingIn };
  } else {
    return { loggedOut };
  }
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedLoginPage = connect(mapStateToProps, actionCreators)(Login);

export { connectedLoginPage as Login };
