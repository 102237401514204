import { manageUserConstants } from '../constants/index';

const initialState = [];

export function manageUserReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case manageUserConstants.UPDATE_USER:
            console.log("UPDATE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case manageUserConstants.ADD_USER:
            console.log("ADD Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case manageUserConstants.DELETE_USER:
            console.log("DELETE Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}