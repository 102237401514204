import React, { Component, Suspense } from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { Login } from './modules/account/Login';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
//const Login = React.lazy(() => import('./modules/account/Login.js'))
const FirstLogin = React.lazy(() => import('./modules/account/firstLogin.js'))
const SetPassword = React.lazy(() => import('./modules/account/setPassword.js'))
const ForgotPasswordS1 = React.lazy(() => import('./modules/account/forgot_password_s1.js'))
const ForgotPasswordS2 = React.lazy(() => import('./modules/account/forgot_password_s2.js'))

const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/first-login" name="Login Page" element={<FirstLogin />} />
            <Route exact path="/set-password" name="Login Page" element={<SetPassword />} />
            <Route exact path="/forgot_password/verify_email" name="Login Page" element={<ForgotPasswordS1 />} />
            <Route exact path="/forgot_password/set_password" name="Login Page" element={<ForgotPasswordS2 />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
