import axios from 'axios';

import { authenticationService } from "../services"

const currentUser = authenticationService.currentUserValue;

export const announcementService = {
    getAnnouncements,
    addAnnouncements,
    delAnnouncements,
    modifyAnnouncements
};

async function getAnnouncements() {
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_GET_ANNOUNCEMENTS}`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        console.log(response)
        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function addAnnouncements(data) {
    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_ADD_ANNOUNCEMENTS}`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: data
        });

        console.log(response)
        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function delAnnouncements(id) {
    try {
        const response = await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_DEL_ANNOUNCEMENTS}/${id}`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function modifyAnnouncements(data) {
    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_ANNOUNCEMENTS_ACTION}`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: data
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log(data)
        if (!response.ok) {
            if (response.status === 401) {
                authenticationService.logout();
                window.location.reload(true);
            } else if (response.status === 406) {
                window.location.href = '/#/first-login'
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}