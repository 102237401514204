import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { API_URL_ROOT } from "../actions/action.constant";
import { authenticationService } from '../services';

const currentUser = authenticationService.currentUserValue;

export const departmentsService = {
    getDepartment,
    getDepartments,
    addDepartments,
    deleteDepartments,
    updateDepartments,
    addDepartmentMembers
};

async function getDepartments() {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_ALL_DEPARTMENTS}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
    });

    console.log(response.data)
    return response.data
}

async function getDepartment(id) {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_GET_DEPARTMENT}/${id}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
    });

    return response.data
}

async function addDepartments(data) {
    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_ADD_DEPARTMENTS}`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: data
        });

        return response.data
    } catch (error) {
        console.log(error)
    }
}

async function addDepartmentMembers(deptID, data) {
    const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_ADD_DEPARTMENTS_MEMBERS}${deptID}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    return response.data
}

async function updateDepartments(id, data) {
    const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_UPDATE_DEPARTMENTS}${id}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    return response.data
}

async function deleteDepartments(uid) {
    const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_DELETE_DEPARTMENTS}${uid}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    return response.data
}