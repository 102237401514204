import { authenticationService } from '../services';

const currentUser = authenticationService.currentUserValue;

export const salaryServices = {
    get_emp_salary,
    add_emp_salary,
    get_emp_salaries
}

async function get_emp_salaries(month, year) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token,
        }
    };

    return await fetch(process.env.REACT_APP_API + process.env.REACT_APP_API_GET_EMP_SALARIES_BY_TIME + `?payment_month=${month}&payment_year=${year}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log({ 'all_emp_salaries': user })
            return { 'all_emp_salaries': user }
        });
}

async function get_emp_salary(employee_id, month, year) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        }
    };

    return await fetch(process.env.REACT_APP_API + process.env.REACT_APP_API_GET_EMP_SALARY_BY_TIME + `?employee_id=${employee_id}&payment_month=${month}&payment_year=${year}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log({ 'single_emp_salary': user })
            return { 'single_emp_salary': user }
        });
}

async function add_emp_salary(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + currentUser.token
        },
        body: data,
    };

    return await fetch(process.env.REACT_APP_API + process.env.REACT_APP_API_ADD_EMP_SALARY, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log(user)
            return user;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                authenticationService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}