import { userConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('currentUser'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {
        loggedOut: true
      };
    case userConstants.FIRST_LOGIN:
      return payload
    case userConstants.CREATE_PASSWORD:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}