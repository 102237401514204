import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { API_URL_ROOT } from "../actions/action.constant";
import { authenticationService } from '../services';

const currentUser = authenticationService.currentUserValue;

export const holidayListService = {
    getHolidays,
    uploadHolidays,
    updateHoliday,
    deleteHoliday,
    deleteAllHoliday,
    getHolidayRegions
};

async function getHolidays() {
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_HOLIDAY_LIST}`,
            headers: { Authorization: `Bearer ${currentUser.token}` }
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function updateHoliday(id, data) {
    try {
        const response = await axios({
            method: "put",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_UPDATE_HOLIDAY}${id}`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: data
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function deleteHoliday(id) {
    try {
        const response = await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_DELETE_HOLIDAY}${id}`,
            headers: { Authorization: `Bearer ${currentUser.token}` }
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function uploadHolidays(data) {
    deleteAllHoliday();

    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_ADD_HOLIDAY}`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: data
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function getHolidayRegions() {
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_HOLIDAY_REGION}`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function deleteAllHoliday() {
    try {
        const response = await axios({
            method: "delete",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_DELETE_ALL_HOLIDAY}`,
            headers: { Authorization: `Bearer ${currentUser.token}` }
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}