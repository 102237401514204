const initialState = {
    pageName: 'Dashboard',
}

export function screenName(state = initialState, { type, ...rest }) {
    switch (type) {
        case 'set':
            console.log(state)
            return { ...state, ...rest }
        default:
            return state
    }
}