import { salariesConstants } from '../constants/index';

const initialState = [];

export function salaryReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case salariesConstants.ADD_EMP_SALARY:
            return payload
        case salariesConstants.GET_EMP_SALARY:
            return {
                ...state,
                ...payload
            }
        case salariesConstants.GET_EMP_SALARIES:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}