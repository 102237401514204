import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
//import { registration } from './registration.reducer';
//import { users } from './users.reducer';
//import { alert } from './alert.reducer';
import { changeState } from './sidebar.reducer';
import { manageUserReducer } from './manageuser.reducer'
import { employeeReducer } from './employee.reducer'
import { rolesReducer } from './roles.reducer'
import { jobsReducer } from './jobopenings.reducer'
import { holidayListReducer } from './holidaylist.reducer'
import { screenName } from './screenName.reducer'
import { departmentsReducer } from './departments.reducer'
import { leavemgmtReducer } from './leavemgmt.reducer'
import { announcementsReducer } from './announcements.reducer'
import { salaryReducer } from './salaries.reducer'
import { settingsReducer } from './settings.reducer'

const rootReducer = combineReducers({
  authentication,
  manageUserReducer,
  changeState,
  employeeReducer,
  rolesReducer,
  jobsReducer,
  holidayListReducer,
  screenName,
  departmentsReducer,
  leavemgmtReducer,
  announcementsReducer,
  salaryReducer,
  settingsReducer
  //registration,
  //users,
  //alert
});

export default rootReducer;