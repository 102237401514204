import { departmentsConstrants } from '../constants/index';

const initialState = [];

export function departmentsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case departmentsConstrants.ADD_DEPARTMENTS:
            console.log(payload);
            return state
        case departmentsConstrants.ADD_DEPARTMENTS_MEMBER:
            console.log(payload);
            return state
        case departmentsConstrants.UPDATE_DEPARTMENTS:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        case departmentsConstrants.DELETE_DEPARTMENTS:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        case departmentsConstrants.RETRIEVE_DEPARTMENTS:
            console.log(payload);
            return payload
        default:
            return state;
    }
}