import { rolesConstrants } from '../constants/index';

const initialState = [];

export function rolesReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case rolesConstrants.ADD_ROLE:
            return payload
        case rolesConstrants.UPDATE_ROLE:
            return {
                ...state,
                ...payload
            }
        case rolesConstrants.DELETE_ROLE:
            return {
                ...state,
                ...payload
            }
        case rolesConstrants.RETRIEVE_ROLES:
            return payload
        default:
            return state;
    }
}