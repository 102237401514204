import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { API_URL_ROOT } from "../actions/action.constant";
import { authenticationService } from '../services';

const currentUser = authenticationService.currentUserValue;

export const leavemgmtService = {
    applyLeave,
    pendingLeave,
    approvedLeave,
    acceptRejectLeave,
    leaveByEmp,
    approvedLeaveByEmp,
    remainingEmpLeaves,
    compOffByEmployee,
    weeklyLeavesByEmployee,
    monthlyLeavesByEmployee
};

async function applyLeave(data) {
    const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_APPLY_LEAVE}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    return response
}

async function pendingLeave() {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_PENDING_LEAVE}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    return response
}

async function acceptRejectLeave(data) {
    const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_ACCEPT_REJECT_LEAVE}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    return response
}

async function approvedLeave() {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_APPROVED_LEAVE}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    return response
}

async function leaveByEmp(id) {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_LEAVE_BY_EMP}?employee_id=${id}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    return response.data
}

async function approvedLeaveByEmp(data) {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_APPROVED_LEAVE_BY_EMP}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    console.log(response)
    return response
}

async function remainingEmpLeaves(data) {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_REMAINING_LEAVE_BY_EMP}?employee_id=${data}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    console.log(response)
    return response.data
}

async function compOffByEmployee(data) {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_COMPENSATORY_LEAVE_BY_EMP}?employee_id=${data}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    console.log(response)
    return response.data
}

async function weeklyLeavesByEmployee(data) {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_WEEKLY_LEAVE_BY_EMP}?employee_id=${data}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    console.log(response.data)
    return response.data
}

async function monthlyLeavesByEmployee(data) {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_MONTHLY_LEAVE_BY_EMP}?employee_id=${data}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    console.log(response.data)
    return response.data
}
