import React from 'react'
import default_icon from './logo.svg'

import '../../scss/hrms-style.scss';

const HrButton = (props) => {
    return (
        <button
            className={
                !props.btnblue
                    ? "btn btn-" + props.btnsize + " hrms-btn-common hrms-font-link " + props.custom_class
                    : "btn btn-" + props.btnsize + " hrms-btn-common-blue hrms-font-link" + props.custom_class
            }
            style={{
                minWidth: props.width,
                fontSize: props.fontSize,
                borderRadius: props.button_radius,
                backgroundColor: props.button_bg
            }}
            type={props.type}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {
                props.btnicon
                    ? <div className='d-flex justify-content-center align-items-center'>
                        <img src={props.btnicon} alt="" width={props.iconwidth} />
                        {
                            props.title
                                ? <>
                                    &nbsp;
                                    {props.title}
                                </>
                                : null
                        }
                    </div>
                    : props.title
                        ? props.title
                        : null
            }
        </button>
    )
}

HrButton.defaultProps = {
    btnsize: 'md',
    type: 'button',
    // width: '10%',
    fontSize: '12px',
    iconwidth: '16px',
    button_radius: '7px'
}

export default React.memo(HrButton)