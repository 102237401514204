import { JobOpeningsConstants } from '../constants/index';

const initialState = [];

export function jobsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case JobOpeningsConstants.GET_ALL_JOBS:
            console.log(payload);
            return payload
        case JobOpeningsConstants.ADD_JOBS:
            console.log("ADD JOBS Reducer Payload: " + payload);
            return {
                ...state,
                ...payload
            }
        case JobOpeningsConstants.GET_LATEST_JOB_OPENINGS:
            console.log(payload);
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}